// extracted by mini-css-extract-plugin
export var calendarContainer = "CalendarModule-module--calendarContainer--b7b5e";
export var closerContainer = "CalendarModule-module--closerContainer--062d3";
export var closerImageContainer = "CalendarModule-module--closerImageContainer--ddb3a";
export var closerName = "CalendarModule-module--closerName--ccc20";
export var closerSpan = "CalendarModule-module--closerSpan--be253";
export var closerTextContainer = "CalendarModule-module--closerTextContainer--eb4be";
export var section = "CalendarModule-module--section--17aac";
export var sectionContainer = "CalendarModule-module--sectionContainer--84fe3";
export var text = "CalendarModule-module--text--d2f8b";
export var textContainer = "CalendarModule-module--textContainer--cf506";
export var textStyleLargeTitle = "CalendarModule-module--text-style-large-title--7c34a";
export var textStyleLargestTitle = "CalendarModule-module--text-style-largest-title--43f4f";
export var textStyleMediumTitle = "CalendarModule-module--text-style-medium-title--4591a";
export var textStyleSmallTitle = "CalendarModule-module--text-style-small-title--04c6d";
export var title = "CalendarModule-module--title--5a3e1";