import React, { useEffect, useRef } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { CALL_BOOKED_URL } from '../../constants';
import { useGlobalState } from '../../state/globalStateContext';
import { Closer } from '../../templates/pages/schedule-call';
import { useStoreScheduleOnceCalendarOnLoad } from '../../utils/hooks';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import {
  generateUniqueId,
  getLocalStorageMemoizedValue,
  urlJoin,
  withDataLayer,
  wrapSquareBracketedWithEm,
} from '../../utils/utils';
import Image from '../ui/Image';
import * as styles from './CalendarModule.module.scss';

export type CalendarModuleProps = {
  title: string;
  titleWithCloserParameter: string;
  text: string;
  closer?: Closer | null;
};

interface QueryData {
  allSanityCloser: {
    nodes: Array<Closer>;
  };
}

const CalendarModule = ({
  title,
  titleWithCloserParameter,
  text,
  closer,
}: CalendarModuleProps): React.ReactElement => {
  const data = useStaticQuery<QueryData>(graphql`
    {
      allSanityCloser(filter: { slug: { current: { ne: null } } }) {
        nodes {
          name
          slug {
            current
          }
          image {
            ...SanityImage
          }
          scheduleOnceCalendarId
        }
      }
    }
  `);

  const socalendarScheduleEventTriggered = useRef(false);
  useStoreScheduleOnceCalendarOnLoad([
    {
      paramName: 'closer',
      paramToCalendarId: Object.fromEntries(
        data.allSanityCloser.nodes.map(closer => [
          closer.slug.current,
          closer.scheduleOnceCalendarId,
        ]),
      ),
    },
  ]);
  const { scheduleOnceCalendar } = useGlobalState();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const closerParam = urlSearchParams.get('closer');

    if (scheduleOnceCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !socalendarScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: scheduleOnceCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            socalendarScheduleEventTriggered.current = true;
            window.location.href = urlJoin(CALL_BOOKED_URL, closer ? '?closer=' + closerParam : '');
          }
        },
        false,
      );
    }
  }, [scheduleOnceCalendar]);

  return (
    <>
      <Helmet>
        {scheduleOnceCalendar && (
          // ScheduleOnce embed
          <script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
        )}
      </Helmet>
      <section className={styles.section} id="schedule-call">
        <div className={styles.sectionContainer}>
          <div className={styles.textContainer}>
            {closer && (
              <div className={styles.closerContainer}>
                <div className={styles.closerImageContainer}>
                  <Image image={closer.image} />
                </div>
                <div className={styles.closerTextContainer}>
                  <span className={styles.closerSpan}>Meeting with</span>
                  <span className={styles.closerName}>{closer.name}</span>
                </div>
              </div>
            )}
            <h2 className={styles.title}>
              {closer
                ? wrapSquareBracketedWithEm(
                    titleWithCloserParameter.replace('{closerName}', closer.name),
                  )
                : wrapSquareBracketedWithEm(title)}
            </h2>
            <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
          </div>
          <div className={styles.calendarContainer}>
            {scheduleOnceCalendar && (
              <div
                id={'SOIDIV_' + scheduleOnceCalendar}
                data-so-page={scheduleOnceCalendar}
                data-height="550"
                data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
                data-psz="10"
              ></div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CalendarModule;
