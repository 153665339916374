import React from 'react';

import { BREAKPOINTS } from '../../styles/breakpoints';
import { Closer } from '../../templates/pages/schedule-call';
import { clsx } from '../../utils/utils';
import Image from '../ui/Image';
import * as styles from './CloserModule.module.scss';

export type CloserModuleProps = {
  closer: Closer;
};

const CloserModule = ({ closer }: CloserModuleProps): React.ReactElement => {
  return (
    <section className={clsx(styles.section)}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <Image
            image={closer.image}
            sizes={`(max-width: 958px) 100vw, 680px`}
            dimensions={[
              [900, 500],
              [BREAKPOINTS.tabletHorizontal - 1, 680, 680],
            ]}
            cover
          />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>
            Meet <em>{closer.name}</em>
          </h2>
          <div className={styles.titleDivider}></div>
          {closer.results && closer.results.length > 0 && (
            <>
              <p className={styles.text}>{closer.name} clients already got more than: </p>
              <div className={styles.resultsContainer}>
                {closer.results.map((result, i) => (
                  <div className={styles.resultContainer} key={i}>
                    <span className={styles.resultNumber}>{result.number}</span>
                    <p className={styles.resultSubtitle}>{result.subtitle}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          {closer.results && closer.results.length > 0 && closer.testimonial && (
            <div className={styles.divider}></div>
          )}
          {closer.testimonial && (
            <div className={styles.testimonialContainer}>
              <span className={styles.testimonialTitle}>Client testimonial</span>
              <blockquote className={styles.testimonial}>
                "{closer.testimonial.testimonial}"
              </blockquote>
              <div className={styles.testimonialAuthorContainer}>
                <span className={styles.testimonialAuthor}>{closer.testimonial.author}</span>
                <span className={styles.testimonialAuthorPosition}>
                  {' - '}
                  {closer.testimonial.position}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CloserModule;
