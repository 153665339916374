// extracted by mini-css-extract-plugin
export var contentContainer = "CloserModule-module--contentContainer--442c9";
export var divider = "CloserModule-module--divider--6e4db";
export var imageContainer = "CloserModule-module--imageContainer--8b3c1";
export var resultContainer = "CloserModule-module--resultContainer--b50b6";
export var resultNumber = "CloserModule-module--resultNumber--09bf4";
export var resultSubtitle = "CloserModule-module--resultSubtitle--55cf2";
export var resultsContainer = "CloserModule-module--resultsContainer--48e00";
export var section = "CloserModule-module--section--b6e28";
export var testimonial = "CloserModule-module--testimonial--b9e04";
export var testimonialAuthor = "CloserModule-module--testimonialAuthor--60eb8";
export var testimonialAuthorContainer = "CloserModule-module--testimonialAuthorContainer--4dae7";
export var testimonialAuthorPosition = "CloserModule-module--testimonialAuthorPosition--2c70d";
export var testimonialTitle = "CloserModule-module--testimonialTitle--7124a";
export var text = "CloserModule-module--text--58075";
export var textContainer = "CloserModule-module--textContainer--28857";
export var textStyleLargeTitle = "CloserModule-module--text-style-large-title--c7f8e";
export var textStyleLargestTitle = "CloserModule-module--text-style-largest-title--9353f";
export var textStyleMediumTitle = "CloserModule-module--text-style-medium-title--efed5";
export var textStyleSmallTitle = "CloserModule-module--text-style-small-title--4720b";
export var title = "CloserModule-module--title--f2b62";
export var titleDivider = "CloserModule-module--titleDivider--6915b";