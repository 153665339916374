// extracted by mini-css-extract-plugin
export var caseStudyContainer = "CaseStudiesModule-module--caseStudyContainer--e9821";
export var caseStudySubTitle = "CaseStudiesModule-module--caseStudySubTitle--48828";
export var caseStudyTitle = "CaseStudiesModule-module--caseStudyTitle--516d1";
export var content = "CaseStudiesModule-module--content--d8045";
export var image = "CaseStudiesModule-module--image--c8b43";
export var line = "CaseStudiesModule-module--line--a2102";
export var resultContainer = "CaseStudiesModule-module--resultContainer--a1f51";
export var resultText = "CaseStudiesModule-module--resultText--39659";
export var resultTitle = "CaseStudiesModule-module--resultTitle--7b3d7";
export var resultsContainer = "CaseStudiesModule-module--resultsContainer--04231";
export var resultsWrapper = "CaseStudiesModule-module--resultsWrapper--de138";
export var section = "CaseStudiesModule-module--section--abdad";
export var sectionContainer = "CaseStudiesModule-module--sectionContainer--a62b2";
export var text = "CaseStudiesModule-module--text--fc19e";
export var textStyleLargeTitle = "CaseStudiesModule-module--text-style-large-title--b3e7a";
export var textStyleLargestTitle = "CaseStudiesModule-module--text-style-largest-title--c4b85";
export var textStyleMediumTitle = "CaseStudiesModule-module--text-style-medium-title--72794";
export var textStyleSmallTitle = "CaseStudiesModule-module--text-style-small-title--8e790";
export var title = "CaseStudiesModule-module--title--99311";
export var titleDivider = "CaseStudiesModule-module--titleDivider--40421";
export var topic = "CaseStudiesModule-module--topic--188b4";