import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PageSEO from '../../components/PageSEO';
import {
  AUTHOR_NAME,
  COPYRIGHT_DEFAULT_YEAR,
  FILE_QUERY_PARAM,
  NOAUTODOWNLOAD_QUERY_PARAM,
} from '../../constants';
import { LocalizedSEO, SanityImageType } from '../../fragments';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import { clsx, removeParamsFromQueryParams, slugify, withDataLayer } from '../../utils/utils';

import CTABannerModule, { CTABannerModuleProps } from '../../components/modules/CTABannerModule';
import CalendarModule, { CalendarModuleProps } from '../../components/modules/CalendarModule';
import CaseStudiesModule, {
  CaseStudiesModuleProps,
} from '../../components/modules/CaseStudiesModule';
import CloserModule from '../../components/modules/CloserModule';
import NumbersListModule, {
  NumbersListModuleProps,
} from '../../components/modules/NumbersListModule';
import VideoModule from '../../components/modules/VideoModule';
import Button from '../../components/ui/Button';
import SmartLink from '../../components/ui/SmartLink';
import { useNotifications } from '../../contexts/NotificationsContext';
import logoImageUrl from '../../images/logo.svg';
import logoImageWhiteUrl from '../../images/logoWhite.svg';
import { getSerializers } from '../../serializers';
import { useGlobalState } from '../../state/globalStateContext';
import { RawPortableText } from '../../types';
import * as styles from './schedule-call.module.scss';

const CTA_QUERY_PARAM = 'cta';

export const query = graphql`
  {
    sanityLpViralScheduleCallPage {
      videoSection {
        title
        videoUrl
      }
      calendarSection {
        title
        titleWithCloserParameter
        text
      }
      numbersSection {
        title
        text
        companyResultsToUse
        numbers {
          superTitle
          number
          numberUnit
          subtitle
          description
        }
      }
      caseStudySection {
        title
        caseStudies {
          _id
          title
          category {
            title
          }
          image {
            ...SanityImage
          }
          imageLayout
          resultsNumbers {
            title
            _rawText(resolveReferences: { maxDepth: 4 })
          }
          _rawDescription(resolveReferences: { maxDepth: 4 })
          _rawIntroduction(resolveReferences: { maxDepth: 4 })
          _rawSolution(resolveReferences: { maxDepth: 4 })
        }
      }

      ctaSection {
        title
        text
        buttonText
      }
      seo {
        ...SEO
      }
    }
    sanityGetFileSettings {
      downloadableFiles {
        fileParameterSlug {
          current
        }
        fileUrl
      }
      _rawSuccessMessage(resolveReferences: { maxDepth: 4 })
    }
    allSanityCloser(filter: { slug: { current: { ne: null } } }) {
      nodes {
        name
        slug {
          current
        }
        image {
          ...SanityImage
        }
        results {
          number
          subtitle
        }
        testimonial {
          testimonial
          author
          position
        }
        scheduleOnceCalendarId
      }
    }
  }
`;

export interface Closer {
  name: string;
  slug: {
    current: string;
  };
  image: SanityImageType;
  results?: Array<{
    number: string;
    subtitle: string;
  }>;
  testimonial?: {
    testimonial: string;
    author: string;
    position: string;
  };
  scheduleOnceCalendarId: string;
}

interface ScheduleCallPageProps {
  data: {
    sanityLpViralScheduleCallPage: {
      videoSection: {
        title: string;
        videoUrl: string;
      };
      calendarSection: CalendarModuleProps;
      numbersSection: NumbersListModuleProps;
      caseStudySection: CaseStudiesModuleProps;
      ctaSection: CTABannerModuleProps;
      seo: LocalizedSEO;
    };
    sanityGetFileSettings: {
      downloadableFiles: Array<{
        fileParameterSlug: {
          current: string;
        };
        fileUrl: string;
      }>;
      _rawSuccessMessage: RawPortableText;
    };
    allSanityCloser: {
      nodes: Array<Closer>;
    };
  };
}

const ScheduleCallPage = ({ data }: ScheduleCallPageProps): React.ReactElement => {
  const { theme } = useGlobalState();
  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const { videoSection, calendarSection, caseStudySection, numbersSection, ctaSection, seo } =
    data.sanityLpViralScheduleCallPage;
  const isHidden = useActivateGoogleOptimize() || theme === null;
  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();

  const [ctaVersion, setCtaVersion] = useState<'none' | 'default'>('none');

  const { showNotification } = useNotifications();

  const [closer, setCloser] = useState<Closer | null>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);
    const noAutoDownloadParamValue = urlSearchParams.get(NOAUTODOWNLOAD_QUERY_PARAM) !== null;
    const ctaParamValue = urlSearchParams.get(CTA_QUERY_PARAM);

    const closerParam = urlSearchParams.get('closer');

    const matchingCloser =
      closerParam && data.allSanityCloser.nodes.find(closer => closer.slug.current === closerParam);

    if (matchingCloser) {
      setCloser(matchingCloser);
    }

    if (ctaParamValue !== 'none') {
      setCtaVersion('default');
    }

    if (fileParamValue || noAutoDownloadParamValue) {
      // Remove file and noautodownload query params
      removeParamsFromQueryParams([FILE_QUERY_PARAM, NOAUTODOWNLOAD_QUERY_PARAM]);

      if (fileParamValue) {
        const downloadableFile = data.sanityGetFileSettings.downloadableFiles.find(
          ({ fileParameterSlug }) => slugify(fileParameterSlug.current) === slugify(fileParamValue),
        );
        if (downloadableFile) {
          showNotification(
            <BlockContent
              renderContainerOnSingleChild
              blocks={data.sanityGetFileSettings._rawSuccessMessage}
              serializers={getSerializers(downloadableFile.fileUrl)}
            />,
          );
        }
      }
    }
  }, []);

  return (
    <main
      style={isHidden ? { opacity: 0 } : undefined}
      className={styles.main}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <PageSEO defaultTitle="Schedule a Call" pageSEO={seo} />

      <div className={styles.page}>
        <header className={clsx(styles.header, styles.stickyHeader)}>
          <div className={styles.headerContainer}>
            <img className={styles.logoContainer} src={logoImageUrl} alt="" />
            <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>

            {ctaVersion === 'default' && (
              <SmartLink
                onClick={() => {
                  withDataLayer(dataLayer => {
                    dataLayer.push({ event: 'cta-button-click', context: 'header' });
                  });
                }}
                to={'#schedule-call'}
              >
                <Button className={styles.ctaButton}>{'Schedule a call'}</Button>
              </SmartLink>
            )}
          </div>
        </header>
        <VideoModule title={videoSection.title} videoUrl={videoSection.videoUrl} />
        <CalendarModule {...calendarSection} closer={closer} />
        {closer && <CloserModule closer={closer} />}
        <NumbersListModule {...numbersSection} />
        <CaseStudiesModule
          title={caseStudySection.title}
          caseStudies={caseStudySection.caseStudies}
        ></CaseStudiesModule>
        {ctaVersion === 'default' && <CTABannerModule {...ctaSection} />}
        <footer className={styles.footer}>
          <img src={logoImageWhiteUrl} alt="Logo" className={styles.logoFooter} />
          <div className={styles.footerDivider}></div>
          <div className={styles.copyright}>
            <span>
              Copyright {currentYear} | All rights reserved to {AUTHOR_NAME}
            </span>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default ScheduleCallPage;
