// extracted by mini-css-extract-plugin
export var arrow = "Button-module--arrow--79687";
export var children = "Button-module--children--27820";
export var container = "Button-module--container--d7245";
export var largeButton = "Button-module--largeButton--0ff1a";
export var secondary = "Button-module--secondary--d05b6";
export var textStyleLargeTitle = "Button-module--text-style-large-title--3b4e5";
export var textStyleLargestTitle = "Button-module--text-style-largest-title--0d185";
export var textStyleMediumTitle = "Button-module--text-style-medium-title--30580";
export var textStyleSmallTitle = "Button-module--text-style-small-title--d87df";
export var whiteButton = "Button-module--whiteButton--f9b4f";