import React, { useRef } from 'react';

import { clsx, withDataLayer } from '../../utils/utils';
import Button from '../ui/Button';
import SmartLink from '../ui/SmartLink';
import * as styles from './CTABannerModule.module.scss';
export interface CTABannerModuleProps {
  title: string;
  text: string;
  buttonText: string;
  className?: string;
}

const CTABannerModule = ({
  title,
  text,
  buttonText,
  className,
}: CTABannerModuleProps): React.ReactElement => {
  const sectionRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={sectionRef} className={clsx(styles.container, className)}>
      <div className={styles.background}></div>
      <div className={styles.content}>
        <div className={styles.textContainer} id="cta-section">
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
          <SmartLink
            className={styles.buttonLink}
            onClick={() => {
              withDataLayer(dataLayer => {
                dataLayer.push({ event: 'cta-button-click', context: 'cta-section' });
              });
            }}
            to={'#schedule-call'}
          >
            <Button whiteButton largeButton>
              {buttonText}
            </Button>
          </SmartLink>
        </div>
      </div>
    </div>
  );
};

export default CTABannerModule;
