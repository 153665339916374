import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, wrapSquareBracketedWithEm } from '../../utils/utils';

import Video from '../ui/Video';
import * as styles from './VideoModule.module.scss';

export type VideoModuleProps = {
  videoUrl: string;
  className?: string;
} & (
  | {
      rowLayout?: never;
      title?: string;
      text?: string;
    }
  | {
      rowLayout: true;
      title?: never;
      text: React.ReactNode;
    }
);

const VideoModule = ({
  videoUrl,
  title,
  text,
  rowLayout,
  className,
}: VideoModuleProps): React.ReactElement => {
  return (
    <section id="video" className={clsx(styles.section, rowLayout && styles.rowLayout, className)}>
      <div className={styles.sectionContainer}>
        <div className={styles.textContainer}>
          {title && <h2 className={styles.title}>{wrapSquareBracketedWithEm(title)}</h2>}
          {text &&
            (typeof text === 'string' ? (
              <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
            ) : (
              <div className={styles.text}>{text}</div>
            ))}
        </div>
        <div className={styles.videoContainer}>
          <Video url={videoUrl} />
        </div>
      </div>
      {!rowLayout && <div className={styles.whiteSpace}></div>}
    </section>
  );
};

export default VideoModule;
