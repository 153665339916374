import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import * as styles from './NumbersListModule.module.scss';

interface NumberType {
  superTitle: string;
  number: string;
  numberUnit: string;
  subtitle: string;
}

export type NumbersListModuleProps = {
  title: string;
  text: string;
} & (
  | {
      companyResultsToUse: 'firstFour';
      numbers?: never;
    }
  | {
      companyResultsToUse: 'chooseManually';
      numbers: Array<NumberType>;
    }
);

interface QueryData {
  allSanityCompanyResult: {
    nodes: Array<NumberType>;
  };
}

const NumbersListModule = ({
  title,
  text,
  companyResultsToUse,
  numbers,
}: NumbersListModuleProps): React.ReactElement => {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityCompanyResult(sort: { fields: orderRank }) {
        nodes {
          superTitle
          number
          numberUnit
          subtitle
          description
        }
      }
    }
  `);

  function renderNumberContainer(number: NumberType) {
    return (
      <div className={clsx(styles.numberContainer)}>
        {number.superTitle && <span className={styles.superTitle}>{number.superTitle}</span>}
        <div className={styles.numberWrapper}>
          <span className={styles.number}>{number.number}</span>
          <span className={styles.subtitle}>{number.numberUnit}</span>
        </div>
        {number.subtitle && <p className={styles.description}>{number.subtitle}</p>}
      </div>
    );
  }

  return (
    <section className={clsx(styles.section)}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.titleDivider}></div>
          <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
        </div>
        <div className={styles.numbersContainer}>
          {(companyResultsToUse === 'chooseManually'
            ? numbers
            : staticData.allSanityCompanyResult.nodes
          ).map((result, i) => (
            <React.Fragment key={i}>{renderNumberContainer(result)}</React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NumbersListModule;
