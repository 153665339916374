import React from 'react';
import arrow from '../../images/button_arrow.svg';
import { clsx } from '../../utils/utils';
import * as styles from './Button.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  secondary?: boolean;
  whiteButton?: boolean;
  largeButton?: boolean;
  children: string;
}

const Button = ({
  className,
  secondary,
  whiteButton,
  largeButton,
  children,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      className={clsx(
        className,
        styles.container,
        secondary && styles.secondary,
        whiteButton && styles.whiteButton,
        largeButton && styles.largeButton,
      )}
      {...restProps}
    >
      <span className={styles.children}>{children}</span>
      {largeButton && whiteButton && <img src={arrow} alt="" className={styles.arrow} />}
    </button>
  );
};

export default Button;
