import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { clsx } from '../../utils/utils';

import { SanityImageType } from '../../fragments';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import Image from '../ui/Image';
import * as styles from './CaseStudiesModule.module.scss';

export type CaseStudiesModuleProps = {
  title: string;
  caseStudies: Array<{
    _id: string;
    title: string;
    category?: {
      title: string;
    };
    image: SanityImageType;
    resultsNumbers?: Array<{
      title: string;
      _rawText: RawPortableText;
    }>;
    _rawIntroduction: RawPortableText;
    _rawSolution?: RawPortableText;
  }>;
};

const CaseStudiesModule = ({ title, caseStudies }: CaseStudiesModuleProps): React.ReactElement => {
  return (
    <section className={clsx(styles.section)}>
      <div className={styles.sectionContainer}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.titleDivider}></div>
        </div>
        {caseStudies.map((caseStudy, i) => (
          <div key={i} className={styles.caseStudyContainer}>
            <div className={styles.content}>
              <span className={styles.topic}>Case Study</span>
              <h4 className={styles.caseStudyTitle}>{caseStudy.title}</h4>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.text}
                blocks={caseStudy._rawIntroduction}
                serializers={serializers}
              />
              <div className={styles.line}></div>
              <span className={styles.caseStudySubTitle}>Solution</span>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.text}
                blocks={caseStudy._rawSolution}
                serializers={serializers}
              />

              {caseStudy.resultsNumbers && caseStudy.resultsNumbers?.length > 0 && (
                <div className={styles.resultsContainer}>
                  <span className={styles.caseStudySubTitle}>Results</span>
                  <div className={styles.resultsWrapper}>
                    {caseStudy.resultsNumbers?.map((resultItem, i) => (
                      <span className={styles.resultContainer} key={i}>
                        <span className={styles.resultTitle}>{resultItem.title}</span>
                        <BlockContent
                          renderContainerOnSingleChild
                          className={styles.resultText}
                          blocks={resultItem._rawText}
                          serializers={serializers}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.image}>
              <Image
                image={caseStudy.image}
                cover
                dimensions={[
                  [1200, 674],
                  [1199, 700, 800],
                ]}
                sizes={`(max-width: 1198px) 100vw, 700px`}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CaseStudiesModule;
