// extracted by mini-css-extract-plugin
export var rowLayout = "VideoModule-module--rowLayout--fb8ad";
export var section = "VideoModule-module--section--8879b";
export var sectionContainer = "VideoModule-module--sectionContainer--d9c5e";
export var text = "VideoModule-module--text--bf964";
export var textStyleLargeTitle = "VideoModule-module--text-style-large-title--33988";
export var textStyleLargestTitle = "VideoModule-module--text-style-largest-title--2fd71";
export var textStyleMediumTitle = "VideoModule-module--text-style-medium-title--4218c";
export var textStyleSmallTitle = "VideoModule-module--text-style-small-title--84968";
export var title = "VideoModule-module--title--1a040";
export var videoContainer = "VideoModule-module--videoContainer--9ad0f";
export var whiteSpace = "VideoModule-module--whiteSpace--e65f1";