// extracted by mini-css-extract-plugin
export var buttonLink = "CTABannerModule-module--buttonLink--63946";
export var container = "CTABannerModule-module--container--6f1aa";
export var content = "CTABannerModule-module--content--dae8e";
export var text = "CTABannerModule-module--text--1bbed";
export var textContainer = "CTABannerModule-module--textContainer--caaca";
export var textStyleLargeTitle = "CTABannerModule-module--text-style-large-title--fd523";
export var textStyleLargestTitle = "CTABannerModule-module--text-style-largest-title--35f69";
export var textStyleMediumTitle = "CTABannerModule-module--text-style-medium-title--3ad41";
export var textStyleSmallTitle = "CTABannerModule-module--text-style-small-title--97cf6";
export var title = "CTABannerModule-module--title--a341f";